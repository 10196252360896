/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import CheckWebpFeature from "./src/components/common/ggfx-client/module/webp-checker";
CheckWebpFeature();

export { wrapRootElement } from "./src/apollo/wrap-root-element";


export const onRouteUpdate = ({ location, prevLocation }) => {
  // console.log('new pathname', location.pathname)
  // console.log('old pathname', prevLocation ? prevLocation.pathname : null)
  if (prevLocation && !prevLocation.pathname.match(/new-homes-for-sale/)) {
    if (window)
      window.scroll({
        top: -100,
        behavior: 'smooth'
      });
  }
}


export const onInitialClientRender = () => {
  window.addEventListener('popstate', () =>
    window.location.href = window.location.href
  )
}

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
}) => {
  
    // Avoid to scroll to top when next page trigger click
    // But this feature should condiser with details page back scroll too
    if (location.pathname.match(/new-homes-for-sale/)) {
      return false;
    }
  
    return true;
};